import standardApiRequestAction from '../core/standardApiRequestAction';

export function startSubscriptionTrial() {
  return standardApiRequestAction({
    path: '/api/v1/subscriptions/start_trial',
    method: 'post',
  });
}

export function fetchSubscription(id, fetchParams, requestKey) {
  return standardApiRequestAction({
    path: `/api/v1/subscriptions/${id}`,
    body: fetchParams,
    requestKey,
  });
}

export function cancelSubscription(id, { reason_extra, reason_key } = {}) {
  return standardApiRequestAction({
    path: `/api/v1/subscriptions/${id}/cancel`,
    method: 'patch',
    body: { reason_extra, reason_key },
  });
}

export function uncancelSubscription(id) {
  return standardApiRequestAction({
    path: `/api/v1/subscriptions/${id}/uncancel`,
    method: 'patch',
  });
}
export function pauseSubscription(id, { pause_duration, reason_extra, reason_key }) {
  return standardApiRequestAction({
    path: `/api/v1/subscriptions/${id}/pause`,
    method: 'patch',
    body: { pause_duration, reason_extra, reason_key },
  });
}

export function unpauseSubscription(id) {
  return standardApiRequestAction({
    path: `/api/v1/subscriptions/${id}/unpause`,
    method: 'patch',
  });
}

export function applySubscriptionCancelDiscount(id) {
  return standardApiRequestAction({
    path: `/api/v1/subscriptions/${id}/apply_cancel_discount`,
    method: 'patch',
  });
}

export function purchaseSubscription({
  applyCredits,
  bogo,
  discountCodeId,
  extraOptions,
  intent,
  interval,
  paymentMethod,
  productId,
  subscriptionId,
}) {
  return standardApiRequestAction({
    path: '/api/v1/subscriptions/complete_purchase',
    method: 'post',
    body: {
      product_id: productId,
      payment_method: paymentMethod,
      interval,
      discount_code_id: discountCodeId,
      apply_credits: applyCredits,
      intent,
      subscription_id: subscriptionId,
      extra_options: extraOptions,
      bogo,
    },
  });
}

export function fetchSubscriptions(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/subscriptions',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function createSubscription(params, requestKey) {
  return standardApiRequestAction({
    path: '/api/v1/subscriptions',
    method: 'post',
    body: { subscription: params },
    requestKey,
  });
}

export function adminCancelSubscription(subId, requestKey) {
  return standardApiRequestAction({
    path: `/api/v1/subscriptions/${subId}/admin_cancel`,
    method: 'put',
    requestKey,
  });
}
