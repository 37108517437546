import { v4 as uuidV4 } from 'uuid';

import API from './api';
import normalizeJSON from './normalizeJSON';

export default ({
    body,
    cacheGroupKey,
    destroy,
    instantUpdate,
    method = 'get',
    parentRequestKey,
    path,
    requestKey: requestKeyParam,
  }) =>
  (dispatch, getState) => {
    const requestKey = requestKeyParam !== undefined ? requestKeyParam : uuidV4();

    // avoid double requests (e.g. PartnerUserAutocomplete)
    if (!getState().apiRequests[parentRequestKey]?.fetching) {
      dispatch({ type: 'STANDARD_API_REQUEST', requestKey, parentRequestKey, instantUpdate });

      const apiMethod = method === 'postFormData' ? method : `${method}JSON`;
      const request = API[apiMethod](path, body)
        .then(response => {
          const { meta, ...payload } = normalizeJSON(response);
          dispatch({
            type: 'STANDARD_API_REQUEST_SUCCESS',
            payload,
            requestKey,
            parentRequestKey,
            cacheGroupKey,
            meta: {
              _destroy: destroy,
              ...(Array.isArray(response.data) ?
                { ids: response.data.map(e => Number(e.id)) }
              : { id: Number(response.data?.id) || undefined }),
              ...meta,
            },
          });
        })
        .catch(error => {
          const { meta, ...payload } = error.meta?.payload_update ? normalizeJSON(error.meta?.payload_update) : {};
          dispatch({
            type: 'STANDARD_API_REQUEST_FAIL',
            error,
            requestKey,
            parentRequestKey,
            cacheGroupKey,
            payload,
          });
          return error;
        });
      return {
        request,
        requestKey,
      };
    }
    return { requestKey };
  };

export function standardPushUpdate(payloadArg) {
  const { meta, ...payload } = normalizeJSON(payloadArg);
  return {
    type: 'STANDARD_PUSH_UPDATE',
    payload,
  };
}

export function deleteApiRequest(key) {
  return {
    type: 'STANDARD_API_DELETE_REQUEST',
    key,
  };
}

export function deleteCacheGroupRequests(key) {
  return {
    type: 'STANDARD_API_DELETE_CACHE_GROUP_REQUESTS',
    key,
  };
}

// Middleware implmentation option
// https://redux.js.org/advanced/middleware
// const apiRequestMiddleware = store => next => action => {
//   if (!action.api_request) return next(action);
//
//   const requestKey = action.requestKey || uuidV4();
//
//   next({ type: 'STANDARD_API_REQUEST', requestKey });
//
//   const { path, method = "get", body } = action.api_request;
//   const request = API[`${method}JSON`](path, body)
//     .then(response => {
//       const { meta, ...payload } = normalizeJSON(response);
//       next({
//         type: 'STANDARD_API_REQUEST_SUCCESS',
//         payload,
//         requestKey,
//         meta: {
//           ...(Array.isArray(response.data) ? { ids: response.map(e => e.id) } : { id: response.data?.id }),
//           ...meta
//         }
//       });
//     })
//     .catch(error => {
//       next({
//         type: 'STANDARD_API_REQUEST_FAIL',
//         error,
//         requestKey: uuidV4()
//       });
//     });
//   return {
//     request,
//     requestKey
//   };
// };
//
// // example action creators
// function fetchUser(userId) {
//   return {
//     api_request: {
//       path: `/api/v1/users/${userId}`
//     }
//   };
// }
//
// function updateUser(userId, params) {
//   return {
//     api_request: {
//       path: `/api/v1/users/${userId}`,
//       method: "patch",
//       body: params
//     }
//   };
// }
