import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';

import { getUserDisplayName } from 'my-core/user-utils';

import Avatar from '@mui/material/Avatar';

import { faUser, faUserSecret } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let colorIdx = 0;
const COLORS = ['primary', 'secondary', 'green', 'purple', 'cyan', 'orange'];
function MuiAvatar(props) {
  const { alt, anonymous, avatar: avatarProp, className, color: colorProp, src = 'normal', user, ...rest } = props;
  const avatar = avatarProp || user?.avatar;
  const classes = useStyles(props);
  const [color] = useState(colorProp || COLORS[(colorIdx = (colorIdx + 1) % COLORS.length)]);

  if (anonymous || !avatar || avatar.is_silhouette || !avatar[src]) {
    return (
      <Avatar {...rest} className={classNames(classes.avatar, classes.avatar_size, classes[color], className)}>
        <FontAwesomeIcon
          className={classNames(classes.icon, { [classes.anonymous]: anonymous })}
          icon={anonymous ? faUserSecret : faUser}
        />
      </Avatar>
    );
  }
  return (
    <Avatar
      {...rest}
      alt={alt || `${getUserDisplayName(user) || 'student'} avatar`}
      className={classNames(classes.avatar, classes.avatar_size, className)}
      src={avatar.is_processing ? avatar.original : avatar[src]}
    />
  );
}

const useStyles = makeStyles(
  ({ palette }) => ({
    avatar: {
      color: palette.common.white,
      backgroundColor: palette.common.white,
    },
    avatar_size: ({ size = 120 }) => ({
      width: size,
      height: size,
    }),
    icon: {
      width: ['40%', '!important'],
      height: ['40%', '!important'],
    },
    anonymous: {
      width: ['60%', '!important'],
      height: ['60%', '!important'],
    },

    primary: { backgroundColor: palette.primary.main },
    secondary: { backgroundColor: palette.secondary.main },
    green: { backgroundColor: palette.green.main },
    purple: { backgroundColor: palette.purple.main },
    cyan: { backgroundColor: palette.cyan.main },
    orange: { backgroundColor: palette.orange.main },
  }),
  { name: 'MuiAvatar' },
);

MuiAvatar.propTypes = {
  anonymous: PropTypes.bool,
  avatar: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(COLORS),
  size: PropTypes.number,
  src: PropTypes.string,
};

export default memo(MuiAvatar);
