import {
  add,
  differenceInMinutes,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfWeek,
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  getWeek,
  isSameDay,
  isSameISOWeek,
  isSameSecond,
  isSameWeek,
  isWithinInterval,
  startOfDay,
  startOfHour,
  startOfISOWeek,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';

export function toDate(date) {
  return (
    date ?
      date instanceof Date ?
        date
      : new Date(date)
    : new Date()
  );
}

export function toUTC(date, invert = false) {
  date = toDate(date);
  const mult = invert ? -1 : 1;
  return sub(date, { minutes: mult * date.getTimezoneOffset() });
}
export function beginningOfSemester(date) {
  const d = toDate(date);
  return startOfMonth(sub(d, { months: d.getMonth() % 4 }));
}
export function endOfSemester(date) {
  const d = toDate(date);
  return endOfMonth(add(d, { months: 3 - (d.getMonth() % 4) }));
}

export function getSemester(date) {
  const d = toDate(date);
  return `${SEMESTERS[Math.floor(d.getMonth() / 4)]} ${d.getFullYear()}`;
}

export function convertSemesterToDate(semester) {
  const [season, year] = semester.split(' ');
  return new Date(+year, SEMESTERS.indexOf(season) * 4);
}

export function getSemestersList(lookBack = 3, lookAhead = 1) {
  const initialDate = add(new Date(), { months: 4 * lookAhead });
  return [...Array(lookBack + lookAhead)].map((_, i) => {
    const d = sub(initialDate, { months: 4 * i });
    return {
      start_date: toUTC(beginningOfSemester(d)).toJSON(),
      end_date: toUTC(endOfSemester(d)).toJSON(),
      label: getSemester(d),
    };
  });
}

const SEMESTERS = ['Winter', 'Summer', 'Fall'];

export function startOf(date, duration) {
  const d = toDate(date);
  switch (duration) {
    case 'hour':
      return startOfHour(d);
    case 'day':
      return startOfDay(d);
    case 'week':
      return startOfWeek(d);
    case 'isoWeek':
      return startOfISOWeek(d);
    case 'month':
      return startOfMonth(d);
  }
  throw `Invalid startOf duration: "${duration}"`;
}

export function endOf(date, duration) {
  const d = toDate(date);
  switch (duration) {
    case 'day':
      return endOfDay(d);
    case 'week':
      return endOfWeek(d);
    case 'isoWeek':
      return endOfISOWeek(d);
    case 'month':
      return endOfMonth(d);
  }
  throw `Invalid endOf duration: "${duration}"`;
}

export function getWeekRange(date) {
  return [startOf(date, 'week'), endOf(date, 'week')];
}

// Intl.DateTimeFormat().resolvedOptions().timeZone
export const CURRENT_TIMEZONE =
  new Date().toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ').pop() || '';
export const CURRENT_TIMEZONE_NAME = window?.Intl?.DateTimeFormat().resolvedOptions().timeZone;

const curTzOffset = new Date().getTimezoneOffset();
export function timeAdjust(time, tzInfo, invert = false) {
  if (tzInfo) {
    const offsetMins = curTzOffset - tzInfo.utc_offset;
    const mult = invert ? -1 : 1;
    return addDate(time, { minutes: offsetMins * mult });
  } else {
    return new Date(time);
  }
}

export function subtractDate(d, opts) {
  return sub(toDate(d), opts);
}

export function addDate(d, opts) {
  return add(toDate(d), opts);
}

export const isBetweenDates = isWithinInterval;

export { getWeek };

export function isBeforeDate(d1, d2) {
  return toDate(d1) < toDate(d2);
}
export function isAfterDate(d1, d2) {
  return toDate(d1) > toDate(d2);
}

export function approximateDuration(seconds, { excludeDays, shortName = false } = {}) {
  if (!excludeDays) {
    const days = seconds / (24 * 3600);
    if (days > 0.6) {
      return [Math.round(days), 'day'];
    }
  }
  const hours = seconds / 3600;
  if (hours > 3) {
    return [Math.round(hours), shortName ? 'hr' : 'hour'];
  }
  if (hours > 1) {
    return [+hours.toFixed(1), shortName ? 'hr' : 'hour'];
  }
  const mins = seconds / 60;
  if (mins > 1) {
    return [Math.round(mins), shortName ? 'min' : 'minute'];
  }
  return [Math.round(seconds), shortName ? 'sec' : 'second'];
}

export function durationBetweenDates(d1, d2, opts) {
  return formatDistanceStrict(toDate(d1), toDate(d2), opts);
}
export function humanizeDurationSeconds(d, opts) {
  // opts: unit: second|hour|day|..., addSuffix: bool
  return formatDistanceStrict(0, d * 1000, opts);
}

const DATE_FORMAT_MAP = {
  dateShort: 'MMM d, yyyy',
  dateShortNum: 'MM/dd/yy',
  dateMedium: 'MMM do, yyyy',
  dateAndDay: 'EEE MMM do, yyyy',
  dateDayAndTime: 'EEE, MMM do, yyyy • h:mmaaa', // ∙  •
  dateDayAndTimeNoYear: 'EEE, MMM do • h:mmaaa',
  dayOfMonth: 'do',
  monthDay: 'MMM do',
  monthYear: 'MMMM yyyy',
  monthYearShort: 'MM/yyyy',
  month: 'MMM',
  monthLong: 'MMMM',
  ymd: 'yyyy-MM-dd',
  ymdAndTime: 'yyyy-MM-dd h:mmaaa',
  year: 'yyyy',
  time: 'h:mmaaa',
};

// Date/Time Pickers (v6.0.0) do not support `mmaaaa` or `do`
export const PICKER_FORMAT_MAP = {
  dateShort: 'MMM d, yyyy',
  dateMedium: 'MMM d, yyyy',
  dateAndDay: 'EEE MMM d, yyyy',
  dateDayAndTime: 'EEE, MMM d, yyyy • h:mm aaa',
  dateDayAndTimeNoYear: 'EEE, MMM d • h:mm aaa',
  monthDay: 'MMM d',
  ymdAndTime: 'yyyy-MM-dd h:mm aaa',
};

const CONDITIONAL_YEAR_FORMATS = {
  upcomingDateShort: 'MMM d{{Y}}',
  upcomingDate: 'MMM do{{Y}}',
  upcomingDateLong: 'MMMM do{{Y}}',
  upcomingDateAndDay: 'EEE, MMM do{{Y}}',
  upcomingDateAndDayNoOrdinal: 'EEE, MMM d{{Y}}',
  upcomingDateAndTime: 'MMM do{{Y}} • h:mmaaa',
  upcomingDateDayAndTime: 'EEE MMM do{{Y}} • h:mmaaa',
};

const currentYear = new Date().getFullYear();
export function formatDate(date, style) {
  const d = toDate(date);
  const formatStr = DATE_FORMAT_MAP[style];
  if (formatStr) return format(d, formatStr);
  const cyFormatStr = CONDITIONAL_YEAR_FORMATS[style];
  if (cyFormatStr) {
    const includeYear = d.getFullYear() !== currentYear;
    return format(d, cyFormatStr.replace('{{Y}}', includeYear ? ', yyyy' : ''));
  }
  if (style === 'fromNow') return formatDistanceToNow(d, { addSuffix: true });
  if (style === 'fromNowStrict') return formatDistanceToNowStrict(d, { addSuffix: true });
  throw `Invalid formatDate style: "${style}"`;
}

export function formatDateRange(startDate, endDate, style, opts) {
  const d1 = toDate(startDate);
  const d2 = toDate(endDate);

  const monthToken = 'MMM';
  const dayToken = opts?.ordinalDay === false ? 'd' : 'do';

  switch (style) {
    case 'upcomingDate': {
      const includeYears = d1.getFullYear() !== d2.getFullYear();
      const hideSecondMonth = !includeYears && d1.getMonth() === d2.getMonth();
      const includeSecondYear = includeYears || d2.getFullYear() !== currentYear;

      return `${format(d1, `${monthToken} ${dayToken}${includeYears ? ', yyyy' : ''}`)} - ${format(
        d2,
        `${hideSecondMonth ? '' : `${monthToken} `} ${dayToken}${includeSecondYear ? ', yyyy' : ''}`,
      )}`;
    }
    case 'upcomingUTCDate': {
      const d1UTC = toUTC(d1, true);
      const d2UTC = toUTC(d2, true);
      return formatDateRange(d1UTC, d2UTC, 'upcomingDate', opts);
    }
    case 'upcomingDateAndTime':
    case 'upcomingDateDayAndTime':
    case 'time': {
      const showAmPm = d1.getHours() < 12 !== d2.getHours() < 12;

      const showMins = d => opts?.forceMins || d.getMinutes();
      const startTime = format(d1, `h${showMins(d1) ? ':mm' : ''}${showAmPm ? 'aaa' : ''}`);
      const endTime = format(d2, `h${showMins(d2) ? ':mm' : ''}aaa`);
      const timePart = `${startTime} - ${endTime}`;

      if (style === 'upcomingDateAndTime' || style === 'upcomingDateDayAndTime') {
        const includeYear = d1.getFullYear() !== currentYear;
        return `${format(
          d1,
          `${style === 'upcomingDateDayAndTime' ? 'EEE, ' : ''}${monthToken} ${dayToken}${includeYear ? ', yyyy' : ''}`,
        )} • ${timePart}`;
      }
      return timePart;
    }
  }
  throw `Invalid formateDateRange style: "${style}"`;
}

export function isSameDate(date1, date2, duration) {
  const d1 = toDate(date1);
  const d2 = toDate(date2);
  switch (duration) {
    case 'day':
      return isSameDay(d1, d2);
    case 'isoWeek':
      return isSameISOWeek(d1, d2);
    case 'week':
      return isSameWeek(d1, d2);
    default:
      return isSameSecond(d1, d2);
  }
}

export function diff(date1, date2, duration) {
  const d1 = toDate(date1);
  const d2 = toDate(date2);
  switch (duration) {
    case 'minutes':
      return differenceInMinutes(d1, d2);
  }
}

export function monthAndYearToDate(month, year) {
  return new Date(year, month - 1, 15);
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const WEEKDAYS_MIN = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
