import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// import { numberToCurrency } from 'my-utils';
import {
  checkoutBrowserPaymentClicked,
  checkoutBrowserPaymentEnabled,
  checkoutCardPaymentAttempted,
  checkoutExistingCardPaymentAttempted, // checkoutPartiallyButtonClicked
} from 'my-core/gtm-events';

import ErrorMessage from 'my-elements/ErrorMessage';

import PaymentFormCardFields from './PaymentFormCardFields';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import amexDark from 'images/third-party/credit-cards/amex-dark.png';
import amexLight from 'images/third-party/credit-cards/amex-light.png';
import masterCardDark from 'images/third-party/credit-cards/master-card-dark.png';
import masterCardLight from 'images/third-party/credit-cards/master-card-light.png';
import visaDark from 'images/third-party/credit-cards/visa-dark.png';
import visaLight from 'images/third-party/credit-cards/visa-light.png';

// import partiallyImg from 'images/third-party/partially.svg';

const CARD_ICON_MAP = [
  ['visa', visaLight, visaDark],
  ['american-express', amexLight, amexDark],
  ['mastercard', masterCardLight, masterCardDark],
];

const useStyles = makeStyles(
  ({ palette, shape: { borderRadius }, spacing }) => ({
    errorMessage: {},
    authWrapper: {
      padding: spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    authorizeBtn: {
      minWidth: 200,
    },
    radio: {
      padding: 0,
      marginRight: spacing(1.5),
      '&:hover': { backgroundColor: 'transparent' },
    },
    cardIcon: {
      width: 36,
      marginLeft: spacing(0.5),
      verticalAlign: 'bottom',
      borderRadius,
      border: [[1, 'solid', palette.grey[200]]],
    },
    content: {
      maxWidth: 600,
      margin: 'auto',
      marginTop: spacing(2),
      border: [[1, 'solid', palette.divider]],
      // borderTop: 0,
      borderRadius,
      overflow: 'hidden',
    },
    paymentOption: {
      width: '100%',
      display: 'flex',
      padding: spacing(1, 2),
      backgroundColor: palette.background.paper,
      '& ~ &': { borderTop: [[1, 'solid', palette.divider]] },
    },
    paymentOption_disabled: {
      '& > *': { opacity: 0.5 },
    },
    spacer: { flex: 1 },
    partiallyContent: {
      padding: spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paritiallyIcon: { height: 20 },
    partiallyBtn: {
      marginTop: spacing(2),
      backgroundColor: palette.background.paper,
      color: palette.primary.main,
      '& img': { width: 20, margin: spacing(0, 1, 0, -0.5) },
    },
    actions: {
      '& > *': { display: 'block', margin: spacing(2, 'auto'), width: '100%' },
    },
    submitBtn: {},
  }),
  { name: 'PaymentForm' },
);

function PaymentForm(props) {
  const classes = useStyles(props);
  const {
    amount,
    countryCode,
    currency,
    disabled,
    elements,
    // partiallyOfferLink,
    error,
    onCancel,
    onChangeSuccessHandler,
    onSuccess,
    paymentIntent: paymentIntentProp,
    paymentMethod,
    stripe,
    submitButtonProps,
    submitting,
  } = props;

  const paymentIntent =
    ['requires_action', 'requires_payment_method'].includes(paymentIntentProp?.status) && paymentIntentProp;

  const [paymentOption, setPaymentOption] = useState(paymentMethod?.card ? 'existing-pm' : 'card');
  useEffect(() => setPaymentOption(po => (po === 'card' && paymentMethod?.card ? 'existing-pm' : po)), [paymentMethod]);

  const [browserApiEnabled, setBrowserApiEnabled] = useState(false);
  const [browserPaymentMethod, setBrowserPaymentMethod] = useState();

  const [stripeError, setStripeError] = useState();

  const [stripeProcessing, setStripeProcessing] = useState(false);
  const [authDisabled, setAuthDisabled] = useState(false);
  const [autoAuth, setAutoAuth] = useState(false);

  const [cardType, setCardType] = useState('');
  const [cardValidationErrors, setCardValidationErrors] = useState({ _default: true });
  const updateValidationErrors = useCallback((key, val) => {
    setCardValidationErrors(errs => ({ ...errs, _default: false, [key]: val }));
  }, []);
  const [billingDetails, setBillingDetails] = useState({});
  const stripeElementRef = useRef();

  const authorizePaymentIntent = useCallback(
    (onSuccess, paymentIntent, onComplete) => {
      if (stripe) {
        setStripeProcessing(true);
        setAuthDisabled(false);
        stripe.confirmCardPayment(paymentIntent.client_secret).then(function (result) {
          setStripeProcessing(false);
          if (result.error) {
            setAuthDisabled(true);
            setStripeError(result.error);
            // The payment failed -- ask your customer for a new payment method.
          } else {
            // The payment has succeeded.
            onSuccess({ paymentIntent: result.paymentIntent });
          }
          onComplete?.();
        });
      } else {
        onComplete?.();
      }
    },
    [stripe],
  );

  const submitPayment = useCallback(
    (onSuccess, paymentIntent, onComplete) => {
      if (stripeProcessing) return;
      switch (paymentOption) {
        case 'existing-pm': {
          checkoutExistingCardPaymentAttempted();
          if (!paymentIntent) {
            onSuccess({ paymentMethod: paymentMethod.id });
            setAutoAuth(true);
            onComplete?.();
          } else {
            setStripeProcessing(true);
            stripe
              .confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethod.id })
              .then(payload => {
                setStripeProcessing(false);
                if (payload.error) {
                  setStripeError(payload.error);
                } else {
                  setStripeError(null);
                  onSuccess({ paymentIntent: payload.paymentIntent });
                }
                onComplete?.();
              });
          }
          return;
        }
        case 'card': {
          checkoutCardPaymentAttempted();
          setStripeProcessing(true);
          const fixedBillingDetails = {
            name: billingDetails?.name,
            address: { postal_code: billingDetails?.postal_code },
          };
          if (!paymentIntent) {
            stripe
              .createPaymentMethod({
                type: 'card',
                billing_details: fixedBillingDetails,
                card: stripeElementRef.current,
              })
              .then(payload => {
                setStripeProcessing(false);
                if (payload.error) {
                  setStripeError(payload.error);
                } else {
                  setStripeError(null);
                  onSuccess({ paymentMethod: payload.paymentMethod.id });
                  setAutoAuth(true);
                }
                onComplete?.();
              });
          } else {
            stripe
              .confirmCardPayment(paymentIntent.client_secret, {
                payment_method: {
                  billing_details: fixedBillingDetails,
                  card: stripeElementRef.current,
                },
              })
              .then(payload => {
                setStripeProcessing(false);
                if (payload.error) {
                  setStripeError(payload.error);
                } else {
                  setStripeError(null);
                  onSuccess({ paymentIntent: payload.paymentIntent });
                }
                onComplete?.();
              });
          }
          return;
        }
        case 'browser': {
          if (!paymentIntent) {
            onSuccess({ paymentMethod: browserPaymentMethod.id });
            onComplete?.();
          } else {
            setStripeProcessing(true);
            // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
            stripe
              .confirmCardPayment(
                paymentIntent.client_secret,
                { payment_method: browserPaymentMethod.id },
                { handleActions: false },
              )
              .then(payload => {
                if (payload.error) {
                  setStripeError(payload.error);
                  setStripeProcessing(false);
                  onComplete?.();
                } else {
                  if (payload.paymentIntent.status === 'requires_action') {
                    // Let Stripe.js handle the rest of the payment flow.
                    authorizePaymentIntent(onSuccess, paymentIntent, onComplete);
                  } else {
                    // The payment has succeeded.
                    setStripeProcessing(false);
                    onSuccess({ paymentIntent: payload.paymentIntent });
                    onComplete?.();
                  }
                }
              });
          }
        }
      }
    },
    [
      authorizePaymentIntent,
      billingDetails?.name,
      billingDetails?.postal_code,
      browserPaymentMethod?.id,
      paymentMethod?.id,
      paymentOption,
      stripe,
      stripeProcessing,
    ],
  );

  // For built-in browser payment
  const paymentRequest = useMemo(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        currency: currency.toLowerCase(),
        country: VALID_COUNTRY_CODES.includes(countryCode) ? countryCode : 'US',
        total: { amount: Math.round(amount), label: 'default' },
        requestPayerName: true,
      });
      pr.on('cancel', () => setPaymentOption('card'));
      pr.on('paymentmethod', res => {
        setStripeError(null);
        setBrowserPaymentMethod(res.paymentMethod);
        res.complete('success');
      });
      return pr;
    }
  }, [amount, countryCode, currency, stripe]);

  useEffect(() => {
    if (!paymentRequest) {
      setBrowserApiEnabled(false);
      return;
    }
    let mounted = true;
    //canMakePayment resolves to null if no API is available
    paymentRequest.canMakePayment().then(res => {
      if (mounted) {
        setBrowserApiEnabled(!!res);
      }
    });
    return () => {
      mounted = false;
    };
  }, [paymentRequest]);
  useEffect(() => {
    if (browserApiEnabled) checkoutBrowserPaymentEnabled();
  }, [browserApiEnabled]);

  useEffect(() => {
    if (autoAuth && paymentIntent?.status === 'requires_action') {
      authorizePaymentIntent(onSuccess, paymentIntent);
    }
  }, [authorizePaymentIntent, onSuccess, paymentIntent, autoAuth]);

  function handlePaymentOptionSelected(option) {
    setPaymentOption(option);
    switch (option) {
      case 'browser':
        checkoutBrowserPaymentClicked();
        paymentRequest.show();
        break;
    }
  }

  const disableFields = disabled || stripeProcessing;

  const isFormValid =
    !disabled &&
    !stripeProcessing &&
    (paymentOption === 'partially' ||
      paymentOption === 'existing-pm' ||
      (paymentOption === 'browser' && browserPaymentMethod?.id) ||
      (paymentOption === 'card' &&
        !Object.values(cardValidationErrors).some(Boolean) &&
        billingDetails.name &&
        billingDetails.postal_code));

  useEffect(() => {
    if (!onChangeSuccessHandler) return;
    if (!isFormValid) return onChangeSuccessHandler();
    onChangeSuccessHandler({ handler: submitPayment });
  }, [isFormValid, onChangeSuccessHandler, submitPayment]);
  useEffect(() => {
    if (!onChangeSuccessHandler) return;
    return () => onChangeSuccessHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        {(error || stripeError) && (
          <ErrorMessage color="secondary" error={stripeError?.message || error} variant="body2" />
        )}
        {!authDisabled && paymentIntent?.status === 'requires_action' ?
          <div className={classes.authWrapper}>
            <Typography gutterBottom>
              **** **** **** {paymentMethod.card.last4}
              <img
                className={classes.cardIcon}
                src={CARD_ICON_MAP.find(([k]) => k === paymentMethod.card.brand)?.[1]}
              />
            </Typography>
            <Button
              className={classes.authorizeBtn}
              disabled={disableFields}
              onClick={() => authorizePaymentIntent(onSuccess, paymentIntent)}
              size="large"
              variant="contained"
            >
              Authorize
            </Button>
            <Button disabled={disableFields} onClick={() => setAuthDisabled(true)}>
              Use Other Card
            </Button>
          </div>
        : <div className={classes.content}>
            {paymentMethod?.card &&
              renderPanel(
                'existing-pm',
                <>
                  <Typography>Existing Card</Typography>
                  <div className={classes.spacer} />
                  <Typography>**** {paymentMethod.card.last4}</Typography>
                  <img
                    className={classes.cardIcon}
                    src={CARD_ICON_MAP.find(([k]) => k === paymentMethod.card.brand)?.[1]}
                  />
                </>,
              )}
            {renderPanel(
              'card',
              <>
                <Typography>Credit Card</Typography>
                <div className={classes.spacer} />
                {CARD_ICON_MAP.map(t => (
                  <img key={t[0]} className={classes.cardIcon} src={cardType === t[0] ? t[2] : t[1]} />
                ))}
              </>,
              <PaymentFormCardFields
                billingDetails={billingDetails}
                disableFields={disableFields}
                elements={elements}
                onChangeBillingDetails={setBillingDetails}
                onChangeCardType={setCardType}
                onChangeValidationErrors={updateValidationErrors}
                stripeElementRef={stripeElementRef}
                validationErrors={cardValidationErrors}
              />,
            )}
            {browserApiEnabled &&
              renderPanel(
                'browser',
                <>
                  <Typography>Use browser credentials</Typography>
                  <div className={classes.spacer} />
                  {browserPaymentMethod && (
                    <>
                      <Typography>**** {browserPaymentMethod.card.last4}</Typography>
                      <img
                        className={classes.cardIcon}
                        src={CARD_ICON_MAP.find(([k]) => k === browserPaymentMethod.card.brand)?.[1]}
                      />
                    </>
                  )}
                </>,
              )}
            {/* {partiallyOfferLink &&
              renderPanel(
                'partially',
                <>
                  <Typography>Flexible Payment Plan</Typography>
                  <div className={classes.spacer} />
                  <img className={classes.paritiallyIcon} src={partiallyImg} />
                </>,
                <div className={classes.partiallyContent}>
                  <Typography align="center">Click the link button below to pay in installments</Typography>
                  <Button
                    className={classes.partiallyBtn}
                    component="a"
                    target="_blank"
                    href={partiallyOfferLink}
                    onClick={() => checkoutPartiallyButtonClicked()}
                  >
                    <img src={partiallyImg} /> Pay with Partial.ly
                  </Button>
                </div>
              )} */}
          </div>
        }
      </div>
      <Typography color="textSecondary" variant="caption">
        All transactions are secure and encrypted.
      </Typography>
      <div className={classes.actions}>
        {!onChangeSuccessHandler && (
          <Button
            children="Complete Payment" // eslint-disable-line @eslint-react/no-children-prop
            disabled={!isFormValid}
            onClick={() => submitPayment(onSuccess, paymentIntent)}
            size="large"
            variant="contained"
            {...submitButtonProps}
          />
        )}
        {onCancel && (
          <Button color="secondary" disabled={!!(stripeProcessing || submitting)} onClick={onCancel} variant="outlined">
            Cancel
          </Button>
        )}
      </div>
    </>
  );

  function renderPanel(value, title, content) {
    const active = paymentOption === value;
    return (
      <>
        <ButtonBase
          classes={{
            root: classes.paymentOption,
            disabled: classes.paymentOption_disabled,
          }}
          disabled={disableFields}
          disableRipple
          onClick={() => handlePaymentOptionSelected(value)}
        >
          <Radio
            checked={active}
            className={classes.radio}
            color="primary"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            name={`payment-option-${value}`}
            value={value}
          />
          {title}
        </ButtonBase>
        {Boolean(content) && (
          <Collapse in={active}>
            <Divider />
            {content}
          </Collapse>
        )}
      </>
    );
  }
}

const VALID_COUNTRY_CODES = [
  'AE',
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CA',
  'CH',
  'CI',
  'CR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'DO',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GI',
  'GR',
  'GT',
  'HK',
  'HU',
  'ID',
  'IE',
  'IN',
  'IT',
  'JP',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'MY',
  'NL',
  'NO',
  'NZ',
  'PE',
  'PH',
  'PL',
  'PT',
  'RO',
  'SE',
  'SG',
  'SI',
  'SK',
  'SN',
  'TH',
  'TT',
  'US',
  'UY',
];

PaymentForm.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  countryCode: PropTypes.string,
  currency: PropTypes.string.isRequired,
  elements: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  onSuccess: PropTypes.func, //.isRequired,
  purchasing: PropTypes.bool,
  stripe: PropTypes.object,
};

export default PaymentForm;
