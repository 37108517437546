import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { WIZE_SUBSCRIPTION_NAME } from 'my-core/constants';
import { DEFAULT_PLAN, PROMO_POINTS } from 'my-core/subscription-utils';
import { getUserFirstName } from 'my-core/user-utils';

import { SubscriptionPlanItems } from 'my-components/Subscription';
import DialogResponsive from 'my-elements/DialogResponsive';

import SubscriptionCheckoutPayment from './SubscriptionCheckoutPayment';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    root: {
      position: 'relative',
      padding: spacing(2, 4),
    },
    // progress: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100%',
    //   height: 12
    // },
    closeBtn: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
    },
    header: {
      padding: spacing(2, 0, 4),
    },
    plans: {
      marginTop: spacing(2),
      justifyContent: 'space-around',
    },
    content: {},

    points: {
      margin: spacing(2, 0, 0),
    },
    point: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: spacing(1),

      '& > svg': {
        marginTop: 2,
        marginRight: spacing(2),
        color: palette.text.secondary,
      },
    },

    [breakpoints.only('xs')]: {
      header: {},
      content: {},
      points: { marginBottom: spacing(3) },
    },
  }),
  { name: 'SubscriptionCheckoutDialog' },
);

export default function SubscriptionCheckoutDialog(props) {
  const {
    defaultDiscountCodeQuery,
    defaultPlan,
    discountCode,
    intent,
    noDialog,
    onClose,
    onSubmit,
    open,
    paymentIntent,
    paymentMethod,
    purchaseError,
    purchasing,
    successPage,
    user,
  } = props;
  const classes = useStyles(props);

  const [plan, setPlan] = useState(defaultPlan || DEFAULT_PLAN);
  useEffect(() => {
    if (open && defaultPlan) setPlan(defaultPlan);
  }, [open, defaultPlan]);

  return noDialog ? renderContents() : (
      <DialogResponsive
        classes={{ paper: classes.root }}
        disableMobileClose
        fullWidth
        maxWidth="sm"
        onClose={successPage ? onClose : undefined}
        open={open}
      >
        <IconButton className={classes.closeBtn} disabled={purchasing} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
        {renderContents()}
      </DialogResponsive>
    );

  function renderContents() {
    return (
      <>
        <div className={classes.header}>{renderHeaderContent()}</div>
        <div className={classes.content}>
          {successPage ?
            renderConfirmation()
          : <SubscriptionCheckoutPayment
              defaultDiscountCodeQuery={defaultDiscountCodeQuery}
              discountCode={discountCode}
              intent={intent}
              noDialog={noDialog}
              onClose={onClose}
              onSubmit={onSubmit}
              paymentIntent={paymentIntent}
              paymentMethod={paymentMethod}
              plan={plan}
              purchaseError={purchaseError}
              purchasing={purchasing}
              user={user}
            />
          }
        </div>
      </>
    );
  }

  function renderHeaderContent() {
    switch (intent) {
      case 'update_payment':
      case 'retry_payment': {
        if (successPage) {
          return (
            <Typography align="center" variant="h4">
              {intent === 'update_payment' ? 'Payment Info Updated' : 'Payment Succeeded'}
            </Typography>
          );
        }
        return (
          <Typography align="center" variant="h4">
            Update Payment Info
          </Typography>
        );
      }
      default: {
        if (successPage) {
          return (
            <Typography align="center" variant="h4">
              Congrats {getUserFirstName(user)} 🎉
            </Typography>
          );
        }
        return (
          <>
            <div>
              <Typography variant="h4">Wizeprep Plus</Typography>
              <div className={classes.points}>
                {PROMO_POINTS.map(point => (
                  <div key={point} className={classes.point}>
                    <FontAwesomeIcon icon={faCheck} size="lg" />
                    <Typography>{point}</Typography>
                  </div>
                ))}
              </div>
            </div>
            <SubscriptionPlanItems
              className={classes.plans}
              countryCode={user.country_code}
              onClick={plan => setPlan(plan)}
              selectedPlan={plan}
            />
            {/* <Typography variant="caption" color="textSecondary" mt={3} display="block"> */}
            {/*   *Limited time only. Valid through Dec 21, 2022 */}
            {/* </Typography> */}
          </>
        );
      }
    }
  }

  function renderConfirmation() {
    const btnProps = {
      onClick: onClose,
      size: 'large',
      variant: 'contained',
      sx: { display: 'block', mt: 3, width: { xs: '100%', sm: '60%' }, mx: 'auto' },
    };
    switch (intent) {
      case 'retry_payment': {
        return (
          <>
            <Typography align="center" paragraph>
              Your subscription access has been re-activated!
            </Typography>
            <Button {...btnProps}>Continue</Button>
          </>
        );
      }
      case 'update_payment': {
        return (
          <>
            <Typography align="center" paragraph>
              Your new info will be used the next time an invoice for you subscription comes due.
            </Typography>
            <Button {...btnProps}>Close</Button>
          </>
        );
      }
      default:
        return (
          <>
            <Typography align="center" paragraph>
              You have successfully unlocked {WIZE_SUBSCRIPTION_NAME}.
            </Typography>
            <Typography align="center">
              Enjoy unlimited access to on-demand lessons & practice, expert tutors for Q&A, and downloadable study
              guides.
            </Typography>
            <Button {...btnProps}>Get Started</Button>
          </>
        );
    }
  }
}
SubscriptionCheckoutDialog.propTypes = {
  intent: PropTypes.oneOf(['retry_payment', 'update_payment', 'purchase']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  purchaseError: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  purchasing: PropTypes.bool,
  successPage: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};
