import { getPackagePricing, isSubscriptionOffering } from 'my-core/online-course-utils';
import { dataLayer, Intercom } from 'my-core/tag-manager';
import { debounce } from 'my-utils';

import { isMcatCourse } from './course-utils';
import { SUBSCRIPTION_PRICE } from './subscription-utils';
import {
  CustomerIO,
  fbevents,
  gtag,
  Hotjar,
  mixpanel,
  ShareASale,
  tikTokTrack,
  wizeTrack,
  wizeTrack2,
} from './tag-manager';

/******************************
 *      GENERIC EVENT         *
 ******************************/

const LOG_EVENTS = window?.localStorage?.getItem('EVENT_TRACK_LOGGING');
export function trackCustomEvent(
  action,
  eventProps = {},
  { callback: callbackArg, custIO, fb, hotjar, intercom, skipGA } = {},
) {
  if (!skipGA) {
    gtag('event', action, eventProps);
  }

  const callback = !!callbackArg && createCallbackWithTimeout(callbackArg);
  mixpanel.track(action, eventProps, { send_immediately: !!callback }, callback);
  if (intercom) Intercom('trackEvent', action, eventProps);
  if (fb) fbevents('trackCustom', action, eventProps);
  if (custIO || hotjar) {
    const evName = action.toLowerCase().replace(/ +/g, '_');
    if (custIO) CustomerIO.track(evName, { ...eventProps, ...custIO });
    if (hotjar) Hotjar('event', evName);
  }

  if (LOG_EVENTS) console.log('Track: ', action, eventProps); // eslint-disable-line no-console
}

function createCallbackWithTimeout(callback, timeout = 1000) {
  let called = false;
  function wrappedCallback(...args) {
    if (!called) {
      called = true;
      callback(...args);
    }
  }
  setTimeout(wrappedCallback, timeout);
  return wrappedCallback;
}

export function trackCoreWebVitals() {
  import('web-vitals').then(({ onCLS, onFID, onINP, onLCP }) => {
    function trackCWVMetric(metric) {
      trackCustomEvent(`CWV - ${metric.name}`, {
        metric_value: metric.value,
        metric_rating: metric.rating,
        metric_delta: metric.delta,
        metric_id: metric.id,
        metric_nav_type: metric.navigationType,
      });
    }
    onINP(trackCWVMetric);
    onCLS(trackCWVMetric);
    onFID(trackCWVMetric);
    onLCP(trackCWVMetric);
  });
}

export function experimentInitiated(experimentName, variant) {
  trackCustomEvent('Experiment Initiated', { experiment_name: experimentName, experiment_variant: variant });
}

/******************************
 *          APP BAR           *
 ******************************/
export const appSearchPerformed = debounce(query => {
  trackCustomEvent('App Search Performed', { search_string: query }, { skipGA: true });
  fbevents('track', 'Search', { search_string: query });
  gtag('event', 'search', { search_term: query });
}, 2000);

export function appBarNavLinkClicked(label) {
  trackCustomEvent('App Bar Nav Link Clicked', { label });
}

export function chatSupportOpened() {
  trackCustomEvent('Chat Support Opened');
  fbevents('track', 'Contact');
}

export function notificationClicked(notification) {
  trackCustomEvent('Notification Clicked', {
    notification_type: notification.type,
    notification_notifiable_id: notification.notifiable_id,
    notification_notifiable_type: notification.notifiable_type,
    notification_group_key: notification.group_key,
  });
}
export function siteTourStarted(triggerSource) {
  trackCustomEvent('Site Tour Started', { trigger_source: triggerSource });
}
/********************************
 *   USER ACCOUNT/ONBOARDING     *
 ********************************/

export function userAuthFormViewed(source) {
  trackCustomEvent('User Auth Form Viewed', { source }, { fb: true });
}
export function userAuthAttempted(method, source) {
  trackCustomEvent('User Auth Attempted', { method, source });
}
export function userLogInCompleted(callback, user, method, source) {
  mixpanel.identify(user.id);
  trackCustomEvent('User Login Completed', { method, source }, { callback, skipGA: true });
  gtag('event', 'login', { method });
}

export function userSignUpCompleted(callback, user, method, source) {
  fbevents('track', 'CompleteRegistration');
  gtag('event', 'sign_up', { method });
  dataLayer.push({ event: 'sign_up' });
  ShareASale.lead(user);
  CustomerIO.identify(user);
  tikTokTrack('CompleteRegistration');
  // if user session ended without explicitly logging out (signed in on another device), then this event may be attributed to the
  // previously signed in user. So call "mixpanel.identify" to make sure this event is attributed to new user
  mixpanel.identify(user.id);
  trackCustomEvent(
    'User Sign Up Completed',
    { method, source },
    { custIO: true, callback, skipGA: true, hotjar: true },
  );
}

export function reauthenticationFormViewed() {
  trackCustomEvent('User Reauth Form Viewed');
}
export function googleOneTapSignInStateChanged(opts) {
  trackCustomEvent('Google One-Tap Sign State Changed', opts);
}

/********************************************************
 *                      CHECKOUT                        *
 *******************************************************/

export function checkoutCardPaymentAttempted() {
  trackCustomEvent('Checkout Card Payment Attempted');
}

export function checkoutExistingCardPaymentAttempted() {
  trackCustomEvent('Checkout Existing Card Payment Attempted');
}
export function checkoutBrowserPaymentEnabled() {
  trackCustomEvent('Checkout Browser Payment Enabled');
}
export function checkoutBrowserPaymentClicked() {
  trackCustomEvent('Checkout Browser Payment Clicked');
}
export function checkoutPartiallyButtonClicked() {
  trackCustomEvent('Checkout Partially Button Clicked');
}

/********************************************************
 *                     SCHOLARSHIPS                     *
 *******************************************************/
export function scholarshipsLandingPageViewed() {
  trackCustomEvent('Scholarships Landing Page Viewed');
}
export function scholarshipPageViewed(scholarship) {
  trackCustomEvent('Scholarship Page Viewed', {
    scholarship_id: scholarship.id,
    scholarship_name: scholarship.name,
    scholarship_company: scholarship.company_name,
  });
}
export function scholarshipApplyNowClicked(scholarship) {
  trackCustomEvent(
    'Scholarship Apply Now Clicked',
    { scholarship_id: scholarship.id, scholarship_name: scholarship.name },
    { custIO: true },
  );
}

export function scholarshipApplicationCompleted(scholarship) {
  trackCustomEvent('Scholarship Applciation Completed', {
    scholarship_id: scholarship.id,
    scholarship_name: scholarship.name,
  });
}

/********************************************************
 *                     COURSE PAGE                      *
 *******************************************************/

export function coursesPageSearchPerformed({ country, region, schoolId, schoolType, subject }) {
  trackCustomEvent('Courses Page Search Performed', {
    search_country: country,
    search_region: region,
    search_subject: subject,
    search_school_type: schoolType,
    search_school_id: schoolId,
  });
}

export function courseCardClicked(course) {
  trackCustomEvent('Course Card Clicked', {
    course_code: course.code,
    course_name: course.name,
    school_name: course.school?.name || course.school_name,
  });
}

export function coursePageViewed(course, school) {
  trackCustomEvent(
    'Course Page Viewed',
    {
      course_code: course.code,
      course_id: course.id,
      is_high_priority: course.high_priority,
      has_class_rep: course.has_class_rep,
      school_name: school?.name,
      school_id: school?.id,
    },
    { custIO: true, intercom: true },
  );
}

export function coursePageTabClicked(course, school, tab) {
  trackCustomEvent('Course Page Tab Clicked', {
    course_code: course.code,
    course_id: course.id,
    school_name: school?.name,
    school_id: school?.id,
    tab,
  });
}

export function coursePageTabViewed(course, school, tab) {
  trackCustomEvent('Course Page Tab Viewed', {
    course_code: course.code,
    course_id: course.id,
    school_name: school?.name,
    school_id: school?.id,
    tab,
  });
}

export const coursePageOnlineCoursesSearched = debounce((course, query) => {
  trackCustomEvent('Course Page Online Courses Searched', {
    course_code: course.code,
    course_id: course.id,
    school_name: course.school_name,
    school_id: course.school_id,
    query,
  });
}, 3000);

export function studentCourseAdded(course, school) {
  trackCustomEvent(
    'Student Course Added',
    {
      course_code: course.code,
      course_id: course.id,
      school_name: school?.name,
      school_id: school?.id,
    },
    { fb: true },
  );
}

export function courseBookletPageViewed(course, school) {
  trackCustomEvent(
    'Course Booklet Page Viewed',
    {
      course_code: course.code,
      course_id: course.id,
      school_name: school?.name,
      school_id: school?.id,
    },
    { custIO: true },
  );
}

export function onlineCourseBookletRequested(onlineCourseId, path, printParams, bookletGenerated) {
  trackCustomEvent(
    'Online Course Booklet Requested',
    {
      online_course_id: onlineCourseId,
      booklet_path: path,
      print_params: printParams,
      booklet_generated: bookletGenerated,
    },
    { custIO: true },
  );
}
export function onlineCourseBookletBannerLinkClicked() {
  trackCustomEvent('Online Course Booklet Banner Link Clicked');
}

/********************************************************
 *                ONLINE COURSE PURCHASE                *
 *******************************************************/

export function onlineCourseCheckoutStepViewed(onlineCourse, step, packageType) {
  trackCustomEvent(
    'Online Course Checkout Step Viewed',
    { ...getOnlineCourseEventProperties(onlineCourse), checkout_step: step },
    { skipGA: true },
  );

  if (step === 1) {
    fbevents('track', 'InitiateCheckout', {
      content_category: 'online-course',
      currency: onlineCourse.currency,
      value: onlineCourse.price,
    });
    gtag('event', 'begin_checkout', {
      items: [eecOnlineCourse(onlineCourse, packageType)],
      currency: onlineCourse.currency,
      value: onlineCourse.price,
    });
    wizeTrack('online_course_checkout_view', onlineCourse.id, 'OnlineCourse');
    CustomerIO.track('product_checkout_viewed', {
      product_id: `OC${onlineCourse.id}`,
      product_type: 'online-course',
      product_price: onlineCourse.price,
      product_name: [onlineCourse.course_code || onlineCourse.course?.code, onlineCourse.name]
        .filter(Boolean)
        .join(': '),
      online_course_type: onlineCourse.course_type,
    });
  } else {
    gtag('event', 'checkout_progress', { items: [eecOnlineCourse(onlineCourse, packageType)], checkout_step: step });
  }
}

export function onlineCoursePurchased(onlineCourse, onlineCourseUser, { discountCode, paymentDetails }) {
  const txnId = `OC_${onlineCourseUser.id}`;
  const productId = `OC_${onlineCourse.id}`;
  trackCustomEvent(
    'Online Course Purchased',
    {
      ...getOnlineCourseEventProperties(onlineCourse),
      revenue: paymentDetails.total,
      currency: onlineCourse.currency,
      discount_code: discountCode,
    },
    { skipGA: true, custIO: true },
  );
  fbevents('track', 'Purchase', {
    content_ids: [productId],
    content_type: 'product',
    content_name: getOcLabel(onlineCourse),
    value: paymentDetails.total,
    currency: onlineCourse.currency,
  });
  gtag('event', 'purchase', {
    transaction_id: txnId,
    value: paymentDetails.total,
    currency: onlineCourse.currency,
    tax: paymentDetails.tax,
    coupon: discountCode,
    items: [eecOnlineCourse(onlineCourse, onlineCourseUser.package_type, discountCode)],
  });
  Intercom('trackEvent', 'purchase-online-course', {
    online_course_id: onlineCourse.id,
    online_course_name: `${onlineCourse.course_code}: ${onlineCourse.name}`,
    online_course_school: onlineCourse.school_name,
    price: { amount: parseInt(paymentDetails.total * 100), currency: onlineCourse.currency },
  });

  ShareASale.purchase({
    orderId: txnId,
    productId: productId,
    amount: paymentDetails.total,
    currency: onlineCourse.currency,
    discountCode,
  });
  // Snapchat, Reddit, etc still in GTM.
  dataLayer.push({
    event: 'purchase',
    txn_id: txnId,
    value: paymentDetails.total,
    currency: onlineCourse.currency,
    product_type: 'OnlineCourse',
    product_id: onlineCourse.id,
  });
}

export function onlineCourseRefunded(onlineCourseUser, amount) {
  trackCustomEvent(
    'Online Course Refunded',
    { online_course_id: onlineCourseUser.online_course_id, amount },
    { skipGA: true },
  );
  gtag('event', 'refund', {
    transaction_id: `T${onlineCourseUser.id}`,
    value: amount,
  });
}

export function onlineCourseInstructorIntroViewed(onlineCourse) {
  trackCustomEvent('Online Course Instructor Intro Viewed', getOnlineCourseEventProperties(onlineCourse));
}

export function onlineCourseFreeRegistered(onlineCourse) {
  trackCustomEvent('Online Course Free Registered', getOnlineCourseEventProperties(onlineCourse), { custIO: true });
}
export function onlineCourseFreeUnregistered(onlineCourse) {
  trackCustomEvent('Online Course Free Unregistered', getOnlineCourseEventProperties(onlineCourse));
}
export function onlineCourseNotificationRequested(onlineCourse) {
  trackCustomEvent('Online Course Notification Requested', getOnlineCourseEventProperties(onlineCourse));
}

export function onlineCourseDiscountCodeSurveyViewed(onlineCourse) {
  trackCustomEvent('Online Course Discount Code Survey Viewed', getOnlineCourseEventProperties(onlineCourse));
}
export function onlineCourseDiscountCodeSurveyCompleted(onlineCourse, funnel) {
  trackCustomEvent('Online Course Discount Code Survey Completed', {
    ...getOnlineCourseEventProperties(onlineCourse),
    survey_field_funnel: funnel,
  });
}

/************************************
 *    ONLINE COURSE EXPERIENCE      *
 ************************************/

export function onlineCourseDashboardViewed(onlineCourse) {
  trackCustomEvent('Online Course Dashboard Viewed', getOnlineCourseEventProperties(onlineCourse));
}

export function onlineCourseViewed(onlineCourse) {
  trackCustomEvent('Online Course Viewed', getOnlineCourseEventProperties(onlineCourse));
  fbevents('track', 'ViewContent', {
    content_ids: [`OC_${onlineCourse.id}`],
    content_type: 'product',
    friendly_name: getOcLabel(onlineCourse),
    value: onlineCourse.price,
    currency: 'CAD',
    name: onlineCourse.name,
    course_code: onlineCourse.courseCode,
    school_name: onlineCourse.schoolName,
    course_id: onlineCourse.courseId,
    school_id: onlineCourse.schoolId,
  });
  wizeTrack('online_course_view', onlineCourse.id, 'OnlineCourse');
}

export function onlineCourseTourButtonClicked(oc) {
  trackCustomEvent('Online Course Tour Button Clicked', getOnlineCourseEventProperties(oc));
}

export const onlineCourseCurriculumSearched = debounce((query, oc, course) => {
  trackCustomEvent('Online Course Curriculum Searched', {
    ...getOnlineCourseEventProperties(oc),
    ...getCourseEventProperties(course),
    query,
  });
}, 3000);

export function courseCurriculumChapterClicked(trackingProps) {
  trackCustomEvent('Course Curriculum Chapter Clicked', trackingProps);
}

export function courseCurriculumSectionClicked(trackingProps) {
  trackCustomEvent('Course Curriculum Section Clicked', trackingProps);
}

export function courseCurriculumActivityClicked(trackingProps) {
  trackCustomEvent('Course Curriculum Activity Clicked', trackingProps);
}

export function courseCurriculumFavoriteActivityClicked(trackingProps) {
  trackCustomEvent('Course Curriculum Favorite Activity Clicked', trackingProps);
}

export function courseCurriculumActivityFavorited(ocActivity, trackingProps) {
  trackCustomEvent(
    'Course Curriculum Activity Favorited',
    { ...getOcActivityProperties(ocActivity), ...trackingProps },
    { custIO: true },
  );
}

export function onlineCourseShareActivityButtonClicked(oc) {
  trackCustomEvent('Online Course Share Activity Button Clicked', getOnlineCourseEventProperties(oc));
}

export function onlineCourseShareActivityUrlCopied(oc) {
  trackCustomEvent('Online Course Share Activity URL Copied', getOnlineCourseEventProperties(oc));
}

export function onlineCourseQuestionAsked(properties) {
  trackCustomEvent('Online Course Question Asked', properties, { custIO: true, intercom: true });
}

export function onlineCourseQuestionReplySubmitted(properties) {
  trackCustomEvent('Online Course Question Reply Submitted', properties);
}

export function onlineCourseActivityViewed(canView, extraProps) {
  trackCustomEvent(
    'Online Course Activity Viewed',
    {
      ...extraProps,
      access_mode: canView ? 'full' : 'preview',
    },
    { custIO: true, hotjar: true },
  );
}

export function onlineCourseActivityCompleted(duration, canView, extraProps) {
  trackCustomEvent(
    'Online Course Activity Completed',
    {
      ...extraProps,
      time_to_complete: duration,
      access_mode: canView ? 'full' : 'preview',
    },
    { hotjar: true },
  );
}

export function onlineCourseActivityDurationViewed(duration, canView, extraProps) {
  trackCustomEvent('Online Course Activity Duration Viewed', {
    ...extraProps,
    duration: duration,
    access_mode: canView ? 'full' : 'preview',
  });
}

export function onlineCourseGatedContentCTAClicked(buttonText, extraProps) {
  trackCustomEvent('Online Course Gated Content CTA Clicked', {
    ...extraProps,
    button_text: buttonText,
  });
}

export function onlineCourseActivityTokenPurchased(trackingProps) {
  trackCustomEvent('Online Course Activity Token Purchased', trackingProps);
}
export function onlineCoursPracticeQuestionTokenPurchased(trackingProps) {
  trackCustomEvent('Online Course Practice Question Token Purchased', trackingProps);
}

export function onlineCoursePracticeQuestionAttempted(isEnabled, submissionResult, skipped, attemptCount, extraProps) {
  trackCustomEvent('Online Course Practice Question Attempted', {
    ...extraProps,
    was_blocked: !isEnabled,
    quiz_question_attempt_points: submissionResult?.points,
    quiz_question_attempt_total: submissionResult?.total,
    quiz_question_attempt_score: submissionResult?.score,
    quiz_question_attempt_skipped: skipped,
    quiz_question_attempt_count: attemptCount,
  });
}

export function onlineCourseQuizQuestionLinkedSectionClicked(trackingProps) {
  trackCustomEvent('Online Course Quiz Question Linked Section Clicked', trackingProps);
}

export function getOcActivityProperties(ocActivity) {
  return (
    ocActivity && {
      activity_type: ocActivity.activity_type,
      activity_id: ocActivity.activity_id,
      oc_activity_id: ocActivity.id,
      activity_subtype:
        ocActivity.activity_metadata?.question_type ||
        ocActivity.activity_metadata?.lesson_type ||
        ocActivity.activity_metadata?.quiz_type,
      video_length: ocActivity.activity_metadata?.duration,
    }
  );
}

export function livePrepCtaBannerClicked(trackingProps) {
  trackCustomEvent('Online Course Live-Prep Banner Clicked', trackingProps);
}
/************************************
 *         OC PRACTICE MODE         *
 ************************************/

export function onlineCoursePracticeModeQuestionSwapped(oc, question, buttonPos) {
  trackCustomEvent('Online Course Practice Mode Question Swapped', {
    ...getOnlineCourseEventProperties(oc),
    instructor_id: question?.user?.id,
    instructor_name: question?.user?.name,
    activity_type: 'QuizQuestion',
    activity_id: question?.id,
    activity_subtype: question?.type,
    video_length: question?.video?.duration,
    button_position: buttonPos,
  });
}

/************************************
 *              VIDEO               *
 ************************************/
export function videoPlayed(videoId, start, end, length, extraProps) {
  const duration = Math.round(end - start);
  trackCustomEvent('Video Played', {
    video_id: videoId,
    video_play_duration: duration,
    video_play_start: start,
    video_play_end: end,
    video_length: length,
    value: duration,
    ...extraProps,
  });
  wizeTrack2('VideoPlayEvent', videoId, { started_at: start, ended_at: end });
}

export function videoCheckpointHit(videoId, checkpoint, extraProps) {
  trackCustomEvent('Video Checkpoint Hit', {
    video_id: videoId,
    ...extraProps,
    value: checkpoint,
    checkpoint,
  });
}

function eecOnlineCourse(oc, packageType = 'pro', discountCode) {
  const [price] = getPackagePricing(oc, packageType);
  return {
    item_id: `OC_${oc.id}`,
    item_name: `(${oc.school_name}) ${oc.course_code}: ${oc.name}`,
    item_brand: oc.instructor_id,
    item_category: `${oc.school_name}/${oc.course_code}/${oc.course_type}/${oc.name}`,
    item_variant: packageType,
    price,
    coupon: discountCode,
    currency: oc.currency,
    quantity: 1,
  };
}

function getOcLabel(oc) {
  return `(${oc.school_name || oc.course?.school_name || oc.course?.school?.name}) ${
    oc.course_code || oc.course?.code
  }: ${oc.name}`;
}

export function getOnlineCourseEventProperties(oc) {
  return {
    online_course_id: oc.id,
    online_course_name: oc.name,
    online_course_price: oc.price,
    online_course_status: oc.status,
    online_course_purchase_type: oc.purchase_type,
    online_course_type: oc.course_type,
    course_code: oc.course_code || oc.course?.code,
    course_id: oc.course_id,
    school_name: oc.school_name || oc.course?.school_name || oc.course?.school?.name,
    school_id: oc.school_id || oc.course?.school_id,
    instructor_id: oc.instructor_id,
    instructor_name: oc.instructor_name,
    is_subscription_offering: isSubscriptionOffering(oc),

    label: getOcLabel(oc),
  };
}

export function getCourseEventProperties(course) {
  return (
    course && {
      course_id: course.id,
      course_code: course.code,
      school_id: course.school_id,
      school_name: course.school_name || course.school?.name,
    }
  );
}

/********************************
 *  STUDY SESSION / LIVE PREP   *
 ********************************/
export function studySessionFreeEnrolled(studySession) {
  trackCustomEvent('Study Session Free Enrolled', studySessionProps(studySession), {
    custIO: {
      study_session_dates: studySession.dates.map(d => ({
        id: d.id,
        start: d.start_time && Math.floor(new Date(d.start_time).getTime() / 1000),
        end: d.end_time && Math.floor(new Date(d.end_time) / 100),
      })),
      study_session_title: studySession.title,
    },
    intercom: true,
    fb: true,
  });
  dataLayer.push({ event: 'study_session_free_enrolled', product_type: 'StudySession', product_id: studySession.id });
}

export function studySessionCheckoutStepViewed(studySession, step, currency) {
  trackCustomEvent(
    'Study Session Checkout Step Viewed',
    { ...studySessionProps(studySession), checkout_step: step },
    { hotjar: true, skipGA: true },
  );
  if (step === 'payment') {
    fbevents('track', 'InitiateCheckout', {
      content_category: 'study-session',
      currency,
      value: studySession.price,
    });
    gtag('event', 'begin_checkout', {
      currency,
      value: studySession.price,
      items: [
        {
          item_id: `SS_${studySession.id}`,
          item_name: [studySession.course?.code, studySession.title].filter(Boolean).join(': '),
          price: studySession.price,
          currency,
        },
      ],
    });
    CustomerIO.track('product_checkout_viewed', {
      product_id: `SS${studySession.id}`,
      product_type: 'study-session',
      product_price: studySession.price,
      product_name: [studySession.course?.code, studySession.title].filter(Boolean).join(': '),
      study_session_type: studySession.type,
    });
  }
}

export function studySessionPurchased(studySession, studySessionUser, { currency, discountCode, total: amount }) {
  const txnId = `SS_${studySessionUser.id}`;
  trackCustomEvent(
    'Study Session Purchased',
    {
      ...studySessionProps(studySession),
      revenue: amount,
      currency,
      discount_code: discountCode?.code,
    },
    {
      custIO: {
        study_session_dates: studySession?.dates.map(d => ({
          id: d.id,
          start: d.start_time && Math.floor(new Date(d.start_time).getTime() / 1000),
          end: d.end_time && Math.floor(new Date(d.end_time) / 100),
        })),
        study_session_title: studySession?.title,
      },
      hotjar: true,
      skipGA: true,
    },
  );
  const friendlyName = [studySession.course?.code, studySession.title].filter(Boolean).join(': ');
  const productId = `SS_${studySession.id}`;
  Intercom('trackEvent', 'purchase-study-session', {
    study_session_id: studySession.id,
    study_session_name: friendlyName,
    school_name: studySession.course?.school_name,
    price: { amount: parseInt(amount * 100), currency },
  });
  fbevents('track', 'Purchase', {
    content_ids: [productId],
    content_type: 'product',
    content_name: friendlyName,
    value: amount,
    currency,
  });
  gtag('event', 'purchase', {
    transaction_id: txnId,
    currency,
    value: amount,
    coupon: discountCode?.code,
    items: [
      {
        item_id: productId,
        item_name: friendlyName,
        price: studySession.price,
        currency,
        quantity: 1,
      },
    ],
  });
  ShareASale.purchase({
    orderId: txnId,
    productId: productId,
    amount,
    currency,
    discountCode: discountCode?.code,
  });

  tikTokTrack('CompletePayment', {
    content_type: 'product',
    content_id: productId,
    value: amount,
    currency,
    quantity: 1,
    price: amount,
  });

  dataLayer.push({
    event: 'purchase',
    txn_id: txnId,
    currency,
    value: amount,
    product_type: 'StudySession',
    product_id: studySession.id,
  });
}

export function studySessionCalendarLinkClicked(studySession, calendar) {
  trackCustomEvent('Study Session Calendar Link Clicked', {
    calendar: calendar,
    ...studySessionProps(studySession),
  });
}

export function studySessionJoinNowClicked(studySession) {
  trackCustomEvent('Study Session Join Now Clicked', studySessionProps(studySession));
}
export function studySessionAttachmentViewed(studySession, type, attachment) {
  trackCustomEvent('Study Session Attachment Viewed', {
    ...studySessionProps(studySession),
    attachment_type: type,
    attachment_id: attachment.id,
    attachment_name: attachment.filename,
  });
}
export function studySessionRecordingViewed(studySession, recording) {
  trackCustomEvent('Study Session Recording Viewed', {
    ...studySessionProps(studySession),
    recording_id: recording.id,
    recording_title: recording.name,
  });
}

function studySessionProps(ss) {
  return (
    ss && {
      study_session_id: ss.id,
      study_session_type: ss.type,
      study_session_mcat_type: ss.mcat_type,
      study_session_mcat_subtype: ss.mcat_subtype,
      course_id: ss.course?.id,
      course_code: ss.course?.code,
      school_name: ss.course?.school?.name,
      school_id: ss.course?.school_id,
      course_ids: ss.course_ids,
      course_codes: ss.courses?.map(c => c.code),
      school_names: ss.courses?.map(c => c.school?.name),
      school_ids: ss.courses?.map(c => c.school_id),
    }
  );
}

/********************************
 *        TUTOR SEARCH          *
 ********************************/

export function tutorLandingPageViewed() {
  trackCustomEvent('Tutor Landing Page Viewed');
}

export function tutorSearchPageResultsViewed(subject, sortOption, count) {
  trackCustomEvent('Tutor Search Page Results Viewed', {
    subject,
    sorting_option: sortOption,
    tutor_count: count,
  });
}

export function tutorLandingSeeMoreLinkClicked() {
  trackCustomEvent('Tutor Landing See More Link Clicked');
}
export function tutorLandingSubjectLinkClicked(subject) {
  trackCustomEvent('Tutor Landing Subject Link Clicked', { subject });
}

export function tutorSearchSubjectSelected(subject) {
  trackCustomEvent('Tutor Search Subject Selected', { subject });
}

export function tutorSearchSortingOptionSelected(option) {
  trackCustomEvent('Tutor Search Sorting Option Selected', { sorting_option: option });
}

export function tutorSearchSeeMoreButtonClicked() {
  trackCustomEvent('See More Results Clicked');
}

export function tutorSearchResultViewProfileClicked(tutor) {
  trackCustomEvent('Search Result View Profile Clicked', { tutor_name: tutor });
}

export function tutorSearchResultSubjectSelected(subject, tutor) {
  trackCustomEvent('Search Result Subject Selected', { subject, tutor_name: tutor });
}

export function tutorSearchResultSubjectDeselected(subject, tutor) {
  trackCustomEvent('Search Result Subject Deselected', { subject, tutor_name: tutor });
}

export function tutorSearchResultBookALessonClicked(tutor) {
  trackCustomEvent('Search Result Book a Lesson Clicked', { tutor_name: tutor });
}

export function tutorSearchResultMessageButtonClicked(tutor) {
  trackCustomEvent('Tutor Search Result Message Button Clicked', { tutor_name: tutor });
}

export function tutorSearchResultConsultationButtonClicked(tutor) {
  trackCustomEvent('Tutor Search Result Consultation Button Clicked', { tutor_name: tutor });
}

export function tutorSearchMessageSent(tutor) {
  trackCustomEvent('Tutor Search Chat Message Sent', { tutor_name: tutor });
}

/********************************
 *     TUTOR BOOKING DIALOG     *
 ********************************/

export function tutorBookingChangeStepButtonClicked(step) {
  trackCustomEvent('Tutor Booking Checkout Step Changed', { step });
}

export function tutorBookingCheckoutStepViewed(stepIdx, stepName, tutorRate) {
  trackCustomEvent('Tutor Booking Checkout Step Viewed', {
    checkout_step: stepIdx,
    checkout_step_name: stepName,
    tutor_rate: tutorRate,
  });
}

/********************************
 *      STUDENT DASHBOARD       *
 ********************************/

export function studentDashboardViewed() {
  trackCustomEvent('Student Dashboard Viewed');
}
export function studentOnboardingViewed(stepCount) {
  trackCustomEvent('Student Onboarding Viewed', { step_count: stepCount });
}
export function studentOnboardingInfoStepCompleted(finalStep, courseCount) {
  trackCustomEvent(
    'Student Onboarding Info Step Completed',
    { final_step: finalStep, course_count: courseCount },
    { hotjar: true },
  );
}
export function studenOnboardingReferralStepCompleted(finalStep, surveyResult) {
  trackCustomEvent('Student Onboarding Referral Step Completed', { final_step: finalStep, ...surveyResult });
}
export function studenOnboardingPlansStepCompleted(finalStep, plan, planOption) {
  trackCustomEvent('Student Onboarding Plans Step Completed', {
    final_step: finalStep,
    selected_plan: plan,
    selected_option: planOption,
  });
}
export function studentOnboardingStepFailed(step, errors) {
  trackCustomEvent('Student Onboarding Step Failed', { step, errors });
}
export function studentOnboardingSkipped() {
  trackCustomEvent('Student Onboarding Skipped');
}
export function dashboardEnrolledOnlineCourseCardClicked(onlineCourse) {
  trackCustomEvent('Dashboard Enrolled Online Course Card Clicked', getOnlineCourseEventProperties(onlineCourse));
}
export function dashboardRecommendedOnlineCourseCardClicked(onlineCourse) {
  trackCustomEvent('Dashboard Recommended Online Course Card Clicked', getOnlineCourseEventProperties(onlineCourse));
}

export function instagramWidgetClicked() {
  trackCustomEvent('Instagram Widget Clicked');
}

/********************************
 *   STUDENT ACCOUNT SETTINGS   *
 ********************************/

/********************************
 *        LANDING PAGES         *
 ********************************/

export function homepageViewed() {
  trackCustomEvent('Homepage Viewed', undefined, { hotjar: true });
}

export function universityLandingPageViewed() {
  trackCustomEvent('University Landing Page Viewed');
}
export function highSchoolLandingPageViewed() {
  trackCustomEvent('High School Landing Page Viewed');
}
export function educatorsLandingPageViewed() {
  trackCustomEvent('Educators Landing Page Viewed');
}
export function parentsLandingPageViewed() {
  trackCustomEvent('Parents Landing Page Viewed');
}

export function medSchoolCalculatorViewed() {
  trackCustomEvent('Med School Calculator Viewed');
}
export function medSchoolCalculatorFiltered(filter) {
  trackCustomEvent('Med School Calculator Filtered', filter);
}

export function onDemandLandingPageViewed() {
  trackCustomEvent('On-Demand Landing Page Viewed');
}
export function livePrepLandingPageViewed() {
  trackCustomEvent('Live Prep Landing Page Viewed');
}

export function seeMoreProfsButtonClicked() {
  trackCustomEvent('See More Profs Button Clicked');
}

export function landingPageBookAChatClicked(chatTarget) {
  trackCustomEvent('Landing Page Book a Chat Clicked', { chat_target: chatTarget });
  dataLayer.push({ event: 'book_a_chat', chat_target: chatTarget });
}
export function schoolsPageViewed() {
  trackCustomEvent('Schools Page Viewed');
}

export function schoolPageViewed(school) {
  trackCustomEvent('School Page Viewed', { school_id: school.id, school_name: school.name });
}

export function promoLandingPageViewed() {
  trackCustomEvent('Promo Landing Page Viewed');
}

export function homepageProductClicked(product) {
  trackCustomEvent('Home Page Product Clicked', { product });
}

export function instructorCardClicked(instructor) {
  trackCustomEvent('Instructor Card Clicked', {
    instructor_id: instructor.id,
    instructor_name: instructor.name,
  });
}

export function landingPageCTAClicked(cta_section, cta_id, cta_sub_id) {
  trackCustomEvent('Landing Page CTA Clicked', { cta_id, cta_section, cta_sub_id });
}

export function landingPageSchoolTypeFilterToggled(school_type) {
  trackCustomEvent('Landing Page School Type Filter Toggled', { school_type });
}

export function homepageSearchSchoolSearched(query) {
  trackCustomEvent('Homepage Search School Searched', { query }, { hotjar: true });
}
export function homepageSearchSchoolSelected(schoolId, schoolName, schoolType) {
  trackCustomEvent(
    'Homepage Search School Selected',
    {
      school_id: schoolId,
      school_name: schoolName,
      school_type: schoolType,
    },
    { hotjar: true },
  );
}
export function homepageSearchCourseSearched(query, inputType, schoolId, schoolName, schoolType) {
  trackCustomEvent(
    'Homepage Search Course Searched',
    {
      query,
      input_type: inputType,
      school_id: schoolId,
      school_name: schoolName,
      school_type: schoolType,
    },
    { hotjar: true },
  );
}
export function homepageSearchCourseSelected(inputType, schoolType, courseCode, courseId) {
  trackCustomEvent(
    'Homepage Search Course Selected',
    {
      input_type: inputType,
      school_type: schoolType,
      course_code: courseCode,
      course_id: courseId,
    },
    { hotjar: true },
  );
}

export function wizeWriterLandingPageViewed() {
  trackCustomEvent('Wize Writer Landing Page Viewed');
}
export function wizeWriterDashboardViewed() {
  trackCustomEvent('Wize Writer Dashboard Viewed');
}
export function wizeWriterEssaySaved() {
  trackCustomEvent('Wize Writer Essay Saved');
}

export function mcatLandingPageViewed(variant) {
  trackCustomEvent('MCAT Landing Page Viewed', { page_variant: variant }, { custIO: true });
}
export function mcatPurchaseButtonClicked(section, scheduleId, product, inPersonLocation) {
  trackCustomEvent(
    'MCAT Purchase Button Clicked',
    {
      page_section: section,
      schedule_id: scheduleId,
      mcat_product: product,
      in_person_location: inPersonLocation || undefined,
    },
    { custIO: true, hotjar: true },
  );
}

export function mcatElite515CheckoutViewed() {
  trackCustomEvent('MCAT Elite 515 Checkout Viewed');
  CustomerIO.track('product_checkout_viewed', {
    product_id: 'mcat-elite-515',
    product_type: 'mcat-elite-515',
    product_name: 'MCAT Elite 515',
  });
}

export function mcatElite515Purchased({ currency, discountCode, payment_option, total, userCourse }) {
  trackCustomEvent(
    'MCAT Elite 515 Purchased',
    { payment_option, revenue: total, currency, discount_code: discountCode?.code },
    { custIO: true },
  );
  Intercom('trackEvent', 'purchase-mcat-elite-515', {
    price: { amount: parseInt(total * 100), currency },
  });
  const txnId = `MCAT_ELITE_${userCourse.id}`;
  const friendlyName = 'MCAT Elite 515';
  const productId = 'MCAT_ELITE_515';

  fbevents('track', 'Purchase', {
    content_ids: [productId],
    content_type: 'product',
    content_name: friendlyName,
    value: total,
    currency,
  });
  gtag('event', 'purchase', {
    transaction_id: txnId,
    currency,
    value: total,
    coupon: discountCode?.code,
    items: [
      {
        item_id: productId,
        item_name: friendlyName,
        price: total,
        currency,
        quantity: 1,
      },
    ],
  });
  ShareASale.purchase({
    orderId: txnId,
    productId: productId,
    amount: total,
    currency,
    discountCode: discountCode?.code,
  });
  tikTokTrack('CompletePayment', {
    content_type: 'product',
    content_id: productId,
    value: total,
    currency,
    quantity: 1,
    price: total,
  });
  dataLayer.push({
    event: 'purchase',
    txn_id: txnId,
    currency,
    value: total,
    product_type: 'MCATElite515',
    product_id: productId,
  });
}

export function builderLandingPageViewed(pageId, pageRevision, pageName) {
  trackCustomEvent('Builder.io Landing Page Viewed', { page_id: pageId, page_rev: pageRevision, page_name: pageName });
}

/********************************
 *        SUBSCRIPTIONS         *
 ********************************/

export function subscriptionLandingPageViewed() {
  trackCustomEvent('Subscription Landing Page Viewed');
}

export function subscriptionStartTrialButtonClicked(countryCode, trackingProps) {
  trackCustomEvent('Subscription Start Trial Button Clicked', trackingProps, {
    custIO: true,
    intercom: true,
    skipGA: true,
    hotjar: true,
  });
  fbevents('track', 'StartTrial', {
    currency: countryCode === 'CA' ? 'CAD' : 'USD',
    value: SUBSCRIPTION_PRICE,
  });
  gtag('event', 'start_trial');
}

export function subscriptionCheckoutDialogViewed(countryCode, trackingProps) {
  trackCustomEvent('Subscription Checkout Dialog Viewed', trackingProps, { custIO: true, skipGA: true, hotjar: true });
  const currency = countryCode === 'CA' ? 'CAD' : 'USD';
  fbevents('track', 'InitiateCheckout', {
    content_category: 'subscription',
    currency,
    value: SUBSCRIPTION_PRICE,
  });
  gtag('event', 'begin_checkout', {
    currency,
    value: SUBSCRIPTION_PRICE,
    items: [{ item_id: 'SUBSCRIPTION', item_name: 'Wize Subscription', price: SUBSCRIPTION_PRICE, currency }],
  });
  CustomerIO.track('product_checkout_viewed', {
    product_id: 'subscription',
    product_type: 'subscription',
    product_price: SUBSCRIPTION_PRICE,
    product_name: 'Wizeprep Plus',
  });
}
export function subscriptionCheckoutCompleted(
  subscription,
  countryCode,
  trackingProps,
  total,
  discountCode,
  bogoEnabled,
) {
  const currency = countryCode === 'CA' ? 'CAD' : 'USD';
  const txnId = `SUB_${subscription.id}`;
  trackCustomEvent(
    'Subscription Checkout Completed',
    {
      revenue: total,
      currency,
      ...trackingProps,
      billing_interval: subscription.billing_interval,
      discount_code: discountCode?.code,
      free_trial: !!discountCode?.subscription_trial,
      stripe_subscription_id: subscription.stripe_subscription_id,
      bogo: bogoEnabled,
    },
    { custIO: true, intercom: true, skipGA: true, hotjar: true },
  );
  fbevents('track', 'Purchase', {
    content_ids: ['SUBSCRIPTION'],
    content_type: 'product',
    content_name: 'Wize Subscription',
    currency,
    value: total,
  });
  gtag('event', 'purchase', {
    transaction_id: txnId,
    currency,
    value: total,
    coupon: discountCode?.code,
    items: [
      {
        item_id: 'SUBSCRIPTION',
        item_name: 'Wize Subscription',
        price: total,
        currency,
        coupon: discountCode?.code,
        quantity: 1,
      },
    ],
  });
  ShareASale.purchase({
    orderId: txnId,
    productId: 'SUBSCRIPTION',
    amount: total,
    currency,
    discountCode: discountCode?.code,
  });
  tikTokTrack('Subscribe', {
    content_type: 'product',
    content_id: subscription.billing_interval === 'yearly' ? 'subscription-yearly' : 'subscription',
    value: total,
    currency,
  });
  dataLayer.push({
    event: 'purchase',
    txn_id: txnId,
    value: total,
    currency,
    product_type: 'Subscription',
    product_id: subscription.billing_interval,
  });
}

export function subscriptionCanceled(reasonProps) {
  trackCustomEvent('Subscription Canceled', { cancellation_reason_key: reasonProps?.reason_key }, { custIO: true });
}
export function subscriptionUncanceled() {
  trackCustomEvent('Subscription Uncanceled', undefined, { custIO: true });
}
export function subscriptionUnpaused() {
  trackCustomEvent('Subscription Unpaused', undefined, { custIO: true });
}

/********************************
 *        COURSE PURCHASE       *
 ********************************/

export function courseCheckoutStepViewed(course, step) {
  trackCustomEvent(
    'Course Checkout Step Viewed',
    {
      course_code: course.code,
      course_id: course.id,
      school_name: course.school_name,
      school_id: course.school_id,
      checkout_step: step,
    },
    { skipGA: true },
  );
  if (step === 'payment') {
    fbevents('track', 'InitiateCheckout', {
      content_category: 'course',
      currency: course.currency,
      value: course.price,
    });
    gtag('event', 'begin_checkout', {
      currency: course.currency,
      value: course.price,
      items: [
        {
          item_id: `C_${course.id}`,
          item_name: [course.school_name, course.code].filter(Boolean).join(': '),
          price: course.price,
          currency: course.currency,
        },
      ],
    });
    CustomerIO.track('product_checkout_viewed', {
      product_id: `C${course.id}`,
      product_type: isMcatCourse(course) ? 'mcat-self-paced' : 'course',
      product_price: course.price,
      product_name: isMcatCourse(course) ? 'MCAT Self-Paced' : course.code,
    });
  }
}

export function coursePurchased(course, userCourse, subscription, { currency, discountCode, total } = {}) {
  total ??= course.price; // in case purchaseDetails not provided (partial.ly payment)
  const txnId = subscription ? `COURSE_SUB_${subscription.id}` : `C_${userCourse.id}`;
  trackCustomEvent(
    'Course Purchased',
    {
      course_id: course.id,
      course_code: course.code,
      school_name: course.school_name,
      school_id: course.school_id,
      revenue: total,
      currency,
      discount_code: discountCode?.code,
    },
    { custIO: true, skipGA: true },
  );
  const friendlyName = [course.school_name, course.code].filter(Boolean).join(': ');
  const productId = `C_${course.id}`;
  Intercom('trackEvent', 'purchase-course', {
    course_id: course.id,
    course_name: friendlyName,
    school_name: course.school_name,
    price: { amount: parseInt(total * 100), currency },
  });
  fbevents('track', 'Purchase', {
    content_ids: [productId],
    content_type: 'product',
    content_name: friendlyName,
    value: total,
    currency,
  });
  gtag('event', 'purchase', {
    transaction_id: txnId,
    currency,
    value: total,
    coupon: discountCode?.code,
    items: [
      {
        item_id: productId,
        item_name: friendlyName,
        price: course.price,
        currency,
        quantity: 1,
      },
    ],
  });
  ShareASale.purchase({
    orderId: txnId,
    productId: productId,
    amount: total,
    currency,
    discountCode: discountCode?.code,
  });
  tikTokTrack('CompletePayment', {
    content_type: 'product',
    content_id: productId,
    value: total,
    currency,
    quantity: 1,
    price: total,
  });
  dataLayer.push({
    event: 'purchase',
    txn_id: txnId,
    currency,
    value: total,
    product_type: 'Course',
    product_id: course.id,
  });
}

/********************************
 *        TEXTBOOK PAGES        *
 ********************************/
export function textbookSubjectsPageViewed(level) {
  trackCustomEvent('Textbook Subjects Page Viewed', { level });
}
export function textbookSubjectPageViewed(level, subject) {
  trackCustomEvent('Textbook Subject Page Viewed', { subject, level });
}
export function textbookSectionPageViewed(oc, section) {
  trackCustomEvent('Textbook Section Page Viewed', {
    subject: oc.subject,
    level: oc.textbook_level,
    online_course_id: oc.id,
    online_course_name: oc.name,
    section_id: section.id,
    section_title: section.title,
    chapter_id: section.chapter?.id,
    chapter_title: section.chapter?.title,
  });
}

/********************************
 *             BLOG             *
 ********************************/
function blogPostMeta(post) {
  return { blog_post_id: post.id, blog_post_title: post.title };
}

export function blogHomeViewed() {
  trackCustomEvent('Blog Home Viewed');
}

export function blogPostViewed(post) {
  trackCustomEvent('Blog Post Viewed', blogPostMeta(post));
}
export function blogPostShareButtonClicked(post) {
  trackCustomEvent('Blog Post Share Button Clicked', blogPostMeta(post));
  wizeTrack('share', post.id, 'BlogPost');
}
export function blogPostPreviousLinkClicked(post) {
  trackCustomEvent('Blog Post Previous Link Clicked', blogPostMeta(post));
}
export function blogPostNextLinkClicked(post) {
  trackCustomEvent('Blog Post Next Link Clicked', blogPostMeta(post));
}
export function blogPostLiked(post) {
  trackCustomEvent('Blog Post Liked', blogPostMeta(post));
}
export function blogPostsFilterSelected(filterName, filterValue) {
  trackCustomEvent('Blog Posts Filter Selected', { filter_name: filterName, filter_value: filterValue });
}
export function blogCarouselButtonClicked(direction) {
  trackCustomEvent('Blog Carousel Button Clicked', { button_direction: direction });
}
export function blogPaginationLinkClicked(pageNum) {
  trackCustomEvent('Blog Pagination Link Clicked', { page_num: pageNum });
}

/********************************
 *             FORUM            *
 ********************************/

export function forumPostContentEntered(props) {
  trackCustomEvent('Forum Post Content Entered', props);
}
export function forumPostCreated(props) {
  trackCustomEvent('Forum Post Created', props);
}

export function forumReplyCreated(props) {
  trackCustomEvent('Forum Reply Created', props);
}

/********************************
 *     INVITES / REFERRALS      *
 ********************************/

export function referralPageViewed(referrer) {
  trackCustomEvent('Referral Page Viewed', { referrer_id: referrer.id });
}
export function referrerPageViewed() {
  trackCustomEvent('Referrer Page Viewed');
}

export function referralActionClicked(action) {
  trackCustomEvent('Referral Action Clicked', { action_type: action });
}
export function referralRedeemCreditsClicked() {
  trackCustomEvent('Referral Redeem Credits Clicked');
}

/********************************
 *      HOMEWORK HELP PAGE      *
 ********************************/

export function homeworkHelpQuestionPageViewed() {
  trackCustomEvent('Homework Help Question Page Viewed');
}

export function homewokHelpQuestionSubmitted(subject) {
  trackCustomEvent('Homewok Help Question Submitted', { subject });
}

export function homeworkHelpQuestionLinkClicked(question) {
  trackCustomEvent('Homework Help Question Link Clicked', { question_id: question.id });
}

export function homeworkHelpResponseRated(isHelpful) {
  trackCustomEvent('Homework Help Response Rated', { is_helpful: isHelpful });
}
