import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { COLOR_NAMES } from 'my-theme/myTheme';

import Typography from '@mui/material/Typography';

const useStyles = makeStyles(
  ({ palette, shape, spacing }) => ({
    root: {
      padding: spacing(0.5, 1.5),
      display: 'inline-block',
      borderRadius: shape.borderRadius,
      textAlign: 'center',
    },
    root_default: {},
    root_light: {},
    root_disabled: {
      backgroundColor: palette.text.disabled,
      color: palette.common.white,
    },
    size_small: {
      padding: [[2, 6]],
    },
    color_grey: {
      '&$root_default': {
        color: palette.background.paper,
        backgroundColor: palette.text.secondary,
      },
      '&$root_light': {
        color: palette.text.secondary,
        backgroundColor: palette.background.paper1,
      },
    },
    color_black: {
      '&$root_default': {
        color: palette.background.paper,
        backgroundColor: palette.text.primary,
      },
      '&$root_light': {
        color: palette.text.primary,
        backgroundColor: palette.background.paper1,
      },
    },
    ...palette.mapColors(c => ({
      [`color_${c}`]: {
        '&$root_default': {
          color: palette[c].contrastText,
          backgroundColor: palette[c].main,
        },
        '&$root_light': {
          color: palette[c][c === 'yellow' ? 'medium' : 'main'],
          backgroundColor: palette[c].background,
        },
      },
    })),
  }),
  { name: 'Label' },
);

const Label = forwardRef(function Label(props, ref) {
  const {
    className,
    color = 'primary',
    disabled,
    label,
    size = 'medium',
    textVariant = 'overline',
    variant = 'default',
    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <Typography
      className={classNames(className, classes.root, classes[`root_${variant}`], {
        [classes[`color_${color}`]]: !disabled,
        [classes.root_disabled]: disabled,
        [classes[`size_${size}`]]: size,
      })}
      ref={ref}
      variant={textVariant}
      {...rest}
    >
      {label}
    </Typography>
  );
});

Label.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([...COLOR_NAMES, 'grey', 'black']),
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(['default', 'light']),
};
export default Label;
