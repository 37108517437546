import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { subscriptionCheckoutCompleted, subscriptionCheckoutDialogViewed } from 'my-core/gtm-events';
import { useCurrentUser, useStandardApiRequest } from 'my-core/hooks';
import { getHasPaymentIssue, getIsActiveOrTrialing, useFetchSubscription } from 'my-core/subscription-utils';

import SubscriptionCheckoutDialog from './SubscriptionCheckoutDialog';

import { purchaseSubscription } from 'my-actions/SubscriptionActions';

export default function SubscriptionCheckoutCtnr({
  defaultDiscountCodeQuery: defaultDiscountCodeQueryProp,
  defaultPlan,
  noDialog,
  onClose,
  open,
  trackingProps,
}) {
  const [successPage, setSuccessPage] = useState(false);
  const currentUser = useCurrentUser();

  const defaultDiscountCodeQuery = useMemo(
    () => defaultDiscountCodeQueryProp || window?.sessionStorage?.getItem('sub_dc'),
    [defaultDiscountCodeQueryProp],
  );

  const [subscription /*, fetchStatus*/] = useFetchSubscription(true);
  const [paymentIntent, discountCode] =
    ['canceled', 'incomplete_expired', 'trialing'].includes(subscription?.status) ?
      []
    : [subscription?.payment_intent, subscription?.discount_code];
  const paymentMethod = subscription?.payment_method;

  const getIntent = () =>
    getHasPaymentIssue(subscription) ? 'retry_payment'
    : subscription?.status === 'active' ? 'update_payment'
    : 'purchase';
  const [intent, setIntent] = useState(getIntent);
  useEffect(() => {
    if (open) setIntent(getIntent());
    else {
      setTimeout(() => setSuccessPage(false), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { performRequest: performPurchase, requestStatus: purchaseStatus } = useStandardApiRequest({
    actionCreator: purchaseSubscription,
  });

  const paymentArgsRef = useRef();
  const handlePayment = useCallback(
    (paymentMethod, callback, total, plan, discountCode, applyCredits, retryPayment, bogoEnabled) => {
      const intent2 = retryPayment ? 'retry_payment' : intent;
      performPurchase({
        paymentMethod: paymentMethod.payment_method || paymentMethod,
        intent: intent2,
        bogo: bogoEnabled,
        ...(intent2 === 'purchase' ?
          {
            interval: plan,
            discountCodeId: discountCode?.id,
            applyCredits,
          }
        : { subscriptionId: subscription.id }),
      });
      paymentArgsRef.current = { callback, total, discountCode, bogoEnabled };
    },
    [intent, performPurchase, subscription?.id],
  );

  const shouldFireCompleteEvent = useRef();
  useEffect(() => {
    if ((open || noDialog) && intent === 'purchase') {
      subscriptionCheckoutDialogViewed(currentUser.country_code, trackingProps);
      shouldFireCompleteEvent.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  useEffect(() => {
    if (!purchaseStatus || purchaseStatus?.fetching) return;
    if (purchaseStatus?.success) {
      if (getIsActiveOrTrialing(subscription)) {
        setSuccessPage(true);
        // Multipe of these components may be mounted at the same time with different `purchaseStatus` attempts cached, so make sure
        // to only fire this in the instance that actually performed the purchase request by checking for paymentArgsRef
        // eg. in-course-experience banner subscription cta attempt to subscribe but card declined, then gated-content subscription successful payment
        if (shouldFireCompleteEvent.current && paymentArgsRef.current) {
          shouldFireCompleteEvent.current = false;
          subscriptionCheckoutCompleted(
            subscription,
            currentUser.country_code,
            trackingProps,
            paymentArgsRef.current.total,
            paymentArgsRef.current.discountCode,
            paymentArgsRef.current.bogoEnabled,
          );
        }
      }
    }
    if (paymentArgsRef.current) {
      paymentArgsRef.current.callback?.(purchaseStatus);
      paymentArgsRef.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't care about trackingProps changing
  }, [purchaseStatus, subscription, currentUser.country_code]);

  return (
    <SubscriptionCheckoutDialog
      defaultDiscountCodeQuery={defaultDiscountCodeQuery}
      defaultPlan={defaultPlan}
      discountCode={discountCode}
      intent={intent}
      noDialog={noDialog}
      onClose={onClose}
      onSubmit={handlePayment}
      open={open}
      paymentIntent={paymentIntent}
      paymentMethod={(intent !== 'update_payment' && paymentMethod) || undefined}
      purchaseError={
        purchaseStatus?.error ||
        (purchaseStatus && !purchaseStatus.fetching && paymentIntent?.last_payment_error?.message)
      }
      purchasing={!!purchaseStatus?.fetching}
      successPage={successPage}
      user={currentUser}
    />
  );
}
