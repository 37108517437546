import { getPlansConfig } from 'my-core/subscription-utils';
import { numberToCurrency } from 'my-utils';

import Label from 'my-elements/Label';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';

import { faBadgePercent } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SubscriptionPlanItem({
  className,
  countryCode,
  ctaProps,
  disabled,
  onClick,
  percentDiscount,
  plan,
  selected,
}) {
  const planConfig = getPlansConfig(countryCode)[plan];
  const amountPerMonth = planConfig.pricing.amountPerMonth;
  const discountPrice = !!percentDiscount && amountPerMonth * (1 - percentDiscount / 100);
  return (
    <ButtonBase
      className={className}
      component="div"
      css={({ palette, shape: { borderRadiusSm }, spacing, typography }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        border: '2px solid',
        backgroundColor: palette.background.paper,
        borderColor: selected ? palette.primary.main : palette.divider,
        borderRadius: borderRadiusSm,
        overflow: 'hidden',
        '& .planOverline': {
          padding: spacing(1),
          backgroundColor: selected ? palette.primary.main : palette.primary.background,
          color: selected ? palette.primary.contrastText : palette.text.secondary,
          borderBottom: '1px solid',
          borderColor: selected ? palette.primary.main : palette.divider,
          textAlign: 'center',
          ...typography.subtitle1,
        },
        '& .planContents': {
          flex: 1,
          color: palette.text.secondary,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: spacing(3, 1),
          '& .planLabel': { marginBottom: spacing(1.5) },
          '& .planPrice': {
            marginBottom: spacing(-0.5),
            ...typography.h4,
            color: palette.primary.medium,
            '& .planPriceReg': {
              color: palette.text.secondary,
              textDecoration: 'line-through',
              textDecorationThickness: 2,
              marginRight: spacing(1),
            },
          },
          '& .planPeriod': { margin: 0 },
          '& .planTag': {
            padding: spacing(0.5, 2),
            margin: spacing(1, 0),
            borderRadius: spacing(1),
            backgroundColor: palette.green.background,
            color: palette.green.dark,
            ...typography.subtitle2,
          },
          '& .planDivider': { width: 100, margin: spacing(1.5, 0) },
          '& .planPromo': { marginBottom: spacing(1) },
          '& .planDescription': {
            flex: 1,
            margin: 0,
            '& .planDescriptionRegPrice': { textDecoration: 'line-through', marginRight: spacing(1) },
          },
          '& .planCta': { marginTop: spacing(2) },
        },
      })}
      disabled={!!disabled}
      onClick={() => onClick?.(plan)}
    >
      <div className="planOverline">{planConfig.label}</div>
      <div className="planContents">
        {percentDiscount && (
          <Label
            className="planLabel"
            color="green"
            label={
              <>
                <FontAwesomeIcon icon={faBadgePercent} />
                {` ${percentDiscount}% off - Limited Time`}
              </>
            }
            variant="light"
          />
        )}
        <div className="planPrice">
          {discountPrice && <span className="planPriceReg">{numberToCurrency(amountPerMonth)}</span>}
          {numberToCurrency(discountPrice || amountPerMonth)}
        </div>
        <p className="planPeriod">per month</p>
        {!percentDiscount && planConfig.savingsPercent ?
          <div className="planTag">{`Save ${planConfig.savingsPercent}%`}</div>
        : <Divider className="planDivider" />}
        {planConfig.promoText && (
          <Label className="planPromo" color="purple" label={planConfig.promoText} size="small" variant="light" />
        )}
        <p className="planDescription">
          {plan === 'yearly' ?
            <>
              {discountPrice && (
                <span className="planDescriptionRegPrice">{numberToCurrency(planConfig.pricing.amount)}</span>
              )}
              {numberToCurrency(discountPrice * 12 || planConfig.pricing.amount)} paid yearly
            </>
          : 'Paid monthly'}
        </p>
        {ctaProps && <Button className="planCta" size="large" {...ctaProps} />}
      </div>
    </ButtonBase>
  );
}
